

























































































































































































































































































































































































































































import Vue from "vue";
import { mapGetters } from "vuex";
import api from "@/api/api";
import { Point } from "geojson";
import {
  EdgeDevice,
  EdgeDeviceCreate,
  EdgeDeviceUpdate,
  EdgeDeviceType,
  Camera,
} from "@/api/models";

type VForm = Vue & { resetValidation: () => boolean };

export default Vue.extend({
  name: "EdgeDeviceForm",

  props: {
    parkingLotId: {
      type: Number,
      required: true,
    },
    existingEdgeDeviceDetails: {
      type: Object as () => EdgeDevice,
      required: false,
    },
    needsInit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    allFieldsValid: false,
    isLoadingCameras: false,
    isLoadingDeviceTypes: false,

    id: null as string | null,
    edgeDeviceId: null as string | null,
    name: "",
    localIpAddress: null as string | null,
    edgeDeviceTypeId: null as string | null,
    allxonDeviceId: null as string | null,
    assignedCameraIds: [] as Array<number>,
    comment: null as string | null,

    publicIpAddress: null as string | null,
    sshPort: null as number | null,
    macAddress: null as string | null,
    username: null as string | null,
    password: null as string | null,
    showPassword: false,

    lastHeartbeatAt: null as string | null,
    createdAt: null as string | null,
    updatedAt: null as string | null,

    camerasInLot: [] as Array<Camera>,
    availableDeviceTypes: [] as Array<EdgeDeviceType>,

    plateReconizer: {
      enabled: false,
      token: "",
      license: "",
    },

    pipelineType: {
      shouldShowPipelineTypeChangedWarning: false,
      items: [
        {
          name: "Standard Vehicle Spot Parking Detection",
          value: "standard_vehicle_detection",
        },
        {
          name: "Vehicle Line Crossing Counting",
          value: "vehicle_counting",
        },
      ],
      selected: "standard_vehicle_detection",
    },

    infoPopup: {
      show: false,
      title: "",
      message: "",
    },
  }),

  mounted() {
    this.resetForm();
    this.initFormWithEdgeDeviceDetails(this.existingEdgeDeviceDetails);
    this.getAllCamerasInLot();
    this.getAllAvailableEdgeDeviceTypes();
  },

  methods: {
    async initFormWithEdgeDeviceDetails(edgeDeviceDetails: EdgeDevice) {
      if (edgeDeviceDetails) {
        this.id = edgeDeviceDetails.id;
        this.edgeDeviceId = edgeDeviceDetails.edge_device_id;
        this.name = edgeDeviceDetails.name;
        this.edgeDeviceTypeId = edgeDeviceDetails.edge_device_type_id;
        this.pipelineType.selected = edgeDeviceDetails.pipeline_type;
        this.allxonDeviceId = edgeDeviceDetails.allxon_device_id;
        this.comment = edgeDeviceDetails.comment;
        this.localIpAddress = edgeDeviceDetails.local_ip_address;
        this.publicIpAddress = edgeDeviceDetails.public_ip_address;
        this.sshPort = edgeDeviceDetails.ssh_port;
        this.macAddress = edgeDeviceDetails.mac_address;
        this.username = edgeDeviceDetails.username;
        this.password = edgeDeviceDetails.password;
        this.lastHeartbeatAt = edgeDeviceDetails.last_heartbeat_at;
        this.assignedCameraIds =
          edgeDeviceDetails.camera_ids_assigned_to_device || [];
        this.plateReconizer.enabled =
          edgeDeviceDetails.pr_api_token != null ||
          edgeDeviceDetails.pr_license_key != null;
        this.plateReconizer.token = edgeDeviceDetails.pr_api_token || "";
        this.plateReconizer.license = edgeDeviceDetails.pr_license_key || "";
        this.createdAt = edgeDeviceDetails.created_at;
        this.updatedAt = edgeDeviceDetails.updated_at;
      }
    },
    async getAllCamerasInLot() {
      console.log("Fetching cameras info");
      this.isLoadingCameras = true;
      let cameras = await api.getAllCameras(this.parkingLotId);
      if (cameras !== null) {
        this.camerasInLot = cameras.filter(
          (c) =>
            c.is_inference_processing_method == "edge_device" ||
            (c.is_lpr_camera_type != null &&
              c.is_lpr_camera_type == "platerecognizer_lpr")
        );
      } else {
        console.log(
          "Unable to load list of cameras for lot id",
          this.parkingLotId
        );
      }
      this.isLoadingCameras = false;
    },
    async getAllAvailableEdgeDeviceTypes() {
      this.isLoadingDeviceTypes = true;
      let deviceTypes = await api.getAvailableEdgeDeviceTypes(
        this.parkingLotId
      );
      if (deviceTypes !== null) {
        this.availableDeviceTypes = deviceTypes;
        if (deviceTypes.length > 0 && !this.edgeDeviceTypeId) {
          this.edgeDeviceTypeId = deviceTypes[0].id;
        }
      } else {
        console.log("Unable to load available edge device types");
      }
      this.isLoadingDeviceTypes = false;
    },
    onPipelineTypeChange() {
      if (
        this.existingEdgeDeviceDetails &&
        this.pipelineType.selected !=
          this.existingEdgeDeviceDetails.pipeline_type
      ) {
        console.log("Pipeline type changed...");
        // do not show pipeline type changed warning as we handle it automatically now
        // this.pipelineType.shouldShowPipelineTypeChangedWarning = true;
      } else {
        this.pipelineType.shouldShowPipelineTypeChangedWarning = false;
      }
    },
    async submitForm() {
      if (!this.edgeDeviceTypeId) {
        this.$dialog.message.error("You must select an edge device and type.", {
          position: "top-right",
          timeout: 3000,
        });
        return;
      }
      let edgeDeviceData = {
        name: this.name,
        edge_device_id: this.edgeDeviceId,
        allxon_device_id: this.allxonDeviceId,
        comment: this.comment,
        local_ip_address: this.localIpAddress,
        edge_device_type_id: this.edgeDeviceTypeId,
        pipeline_type: this.pipelineType.selected,

        public_ip_address: this.publicIpAddress,
        ssh_port: this.sshPort,
        mac_address: this.macAddress,
        username: this.username,
        password: this.password,

        pr_api_token: this.plateReconizer.enabled
          ? this.plateReconizer.token
          : null,
        pr_license_key: this.plateReconizer.enabled
          ? this.plateReconizer.license
          : null,
      };

      if (this.existingEdgeDeviceDetails == null || this.id == null) {
        this.createEdgeDevice({
          ...edgeDeviceData,
          parking_lot_id: this.parkingLotId,
          organization_id: null,
        });
      } else {
        this.updateEdgeDevice({
          ...edgeDeviceData,
          id: this.id,
          camera_ids_assigned_to_device: this.assignedCameraIds,
          is_running: true,
          parking_lot_id: this.existingEdgeDeviceDetails.parking_lot_id,
          organization_id: this.existingEdgeDeviceDetails.organization_id,
        });
      }
    },
    async createEdgeDevice(edgeDeviceData: EdgeDeviceCreate) {
      let edgeDevice = await api.createEdgeDevice(
        this.parkingLotId,
        edgeDeviceData
      );
      if (edgeDevice) {
        this.$dialog.message.info("New Edge Device added successfully", {
          position: "top-right",
          timeout: 3000,
        });
        this.name = edgeDevice.name;
        this.$emit("refresh-data");
        this.$emit("close-form", this.edgeSetupCommand);
      } else {
        this.$dialog.message.error(
          "Error, unable to create new Edge Device. This name may already be in use.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      }
    },
    async updateEdgeDevice(edgeDeviceData: EdgeDeviceUpdate) {
      let edgeDevice = await api.updateEdgeDevice(
        this.parkingLotId,
        edgeDeviceData
      );
      if (edgeDevice) {
        this.$dialog.message.info("Edge Device updated successfully", {
          position: "top-right",
          timeout: 3000,
        });
        this.$emit("refresh-data");
        this.$emit("close-form");
      } else {
        this.$dialog.message.error("Error, unable to update Edge Device", {
          position: "top-right",
          timeout: 3000,
        });
      }
    },
    resetForm(resetName = true) {
      this.id = null;
      this.edgeDeviceId = null;
      this.name = "";
      if (resetName) {
        this.name = "";
      }
      this.comment = null;
      this.edgeDeviceTypeId = null;
      this.localIpAddress = null;
      this.allxonDeviceId = null;
      this.lastHeartbeatAt = null;
      this.assignedCameraIds = [];
      this.publicIpAddress = null;
      this.sshPort = null;
      this.macAddress = null;
      this.username = null;
      this.password = null;

      this.pipelineType.selected = "standard_vehicle_detection";
      this.pipelineType.shouldShowPipelineTypeChangedWarning = false;

      this.createdAt = null;
      this.updatedAt = null;
      (this.$refs.edgeDeviceFormElm as VForm).resetValidation();
    },
    closeForm() {
      this.$emit("close-form");
      this.resetForm(false);
    },
    copyToClipboardEdgeCommand() {
      navigator.clipboard.writeText(this.edgeSetupCommand || "");
    },
    openBenchmarkingSheet() {
      window.open(process.env.VUE_APP_DEVICE_BENCHMARKING_LINK, "_blank");
    },
    showInfoPopup(title: string, message: string) {
      this.infoPopup.title = title;
      this.infoPopup.message = message;
      this.infoPopup.show = true;
    },
  },

  watch: {
    existingEdgeDeviceDetails(edgeDeviceDetails) {
      if (edgeDeviceDetails) {
        this.initFormWithEdgeDeviceDetails(edgeDeviceDetails);
      }
    },
    needsInit(show) {
      if (show) {
        this.getAllCamerasInLot(); // Needs to refetch since camera assignment might have changed
        this.initFormWithEdgeDeviceDetails(this.existingEdgeDeviceDetails);
      } else this.closeForm();
    },
  },

  computed: {
    edgeSetupCommand() {
      return `sudo bash -c "$(wget -qO- ${process.env.VUE_APP_API_URL.substring(
        0,
        process.env.VUE_APP_API_URL.indexOf("/api")
      )}/edge_onboarding.sh)" -s -n "${this.name}" -e "${
        process.env.VUE_APP_ENV
      }"`;
    },

    isCameraAssignmentExceedingDeviceLimit() {
      if (this.existingEdgeDeviceDetails) {
        return (
          this.assignedCameraIds.length >
          this.existingEdgeDeviceDetails.max_supported_streams_count
        );
      }
      return false;
    },
  },
});
