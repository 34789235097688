

































































































































import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { OrganizationUserLoginPayload } from "@/api/models";
import api from "@/api/api";

export default Vue.extend({
  name: "Signin",

  data: () => ({
    allFieldsValid: false,
    email: "",
    password: "",
    showPassword: false,
    authMethod: "",
    errorMessage: "",
  }),

  computed: {
    ...mapGetters("user", ["isLoggedIn", "getCurrentUserData"]),
  },

  mounted() {
    const token = localStorage.getItem("token");
    if (token) {
      this.loginSuccess(token);
      this.redirectHomePage();
    }

    const params = new URLSearchParams(window.location.search);
    const errorCode = params.get("error_code");

    console.log("errorCode ", errorCode);
    const errorMessages: Record<string, string> = {
      USER_NOT_FOUND:
        "If it is correct, please contact your administrator to have an account created for you.",
    };

    if (errorCode) {
      this.errorMessage =
        errorMessages[errorCode] ?? "An unknown error occurred.";
    }
  },

  methods: {
    ...mapMutations("user", ["loginSuccess"]),
    ...mapActions("user", ["login", "initCurrentOrgUser"]),
    async handleLogin() {
      if (this.authMethod === "password") {
        this.submitLogin();
      } else {
        this.submitLoginCheckAuthMethod();
      }
    },
    async submitLogin() {
      console.log("Logging in...");
      let payload: OrganizationUserLoginPayload = {
        username: this.email.toLowerCase(),
        password: this.password,
      };
      try {
        await this.login(payload);
        if (this.isLoggedIn === true) {
          this.redirectHomePage();
        } else {
          console.error("Login Error, please try again.");
          this.$dialog.message.error("Login Error, please try again.", {
            position: "top-right",
            timeout: 3000,
          });
        }
      } catch (error) {
        this.$dialog.message.error(error.response.data.detail, {
          position: "top-right",
          timeout: 3000,
        });
      }
    },
    async submitLoginCheckAuthMethod() {
      console.log("Checking authentication method...");
      try {
        const authResponse = await api.checkAuthMethod(
          this.email.toLowerCase()
        );

        console.log("authResponse ", authResponse);
        if (!authResponse) {
          this.$dialog.message.error("Login Error, please try again.", {
            position: "top-right",
            timeout: 3000,
          });
          return;
        }

        if (authResponse.auth_method === "saml") {
          // Redirect to SAML SSO login page
          await api.samlLoginOrgUser(this.email.toLowerCase());
          return;
        } else if (authResponse.auth_method === "password") {
          this.authMethod = "password";
          this.$nextTick(() => {
            if (this.$refs.passwordField) {
              (this.$refs.passwordField as HTMLInputElement).focus();
            }
          });
        }
      } catch (error) {
        console.error("Error during login process:", error);
        this.$dialog.message.error(
          error.response?.data?.detail || "Login Error, please try again.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      }
    },

    redirectHomePage() {
      this.initCurrentOrgUser();
      setTimeout(() => {
        if (window.history.replaceState) {
          window.history.replaceState(null, "", null);
        }
        if (this.getCurrentUserData) {
          if (this.getCurrentUserData.user_type == "operator") {
            this.$router.replace({ name: "UntrackedZones" });
          } else if (this.getCurrentUserData.user_type == "admin") {
            window.location.replace(
              `${process.env.VUE_APP_3_BASE_URL_PATH}/dashboard`
            );
          } else {
            window.location.replace(
              `${process.env.VUE_APP_3_BASE_URL_PATH}/home`
            );
          }
        } else {
          window.location.replace(
            `${process.env.VUE_APP_3_BASE_URL_PATH}/home`
          );
        }
      }, 300);
    },
  },
});
