























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";
import dayjs, { tz } from "dayjs";

import MapEditor from "../components/MapEditor.vue";

import api from "@/api/api";
import DigiMapBoxes from "../components/DigiMapBoxes.vue";

import {
  ParkingStatus,
  ParkingLot,
  ParkingLotStats,
  ParkingZoneNames,
  ParkingLotOccupancy,
  ParkingHistory,
  CameraSpotCounts,
  PaginatedItems,
  ParkingPermit,
  ParkingLotParkingPermitsSnapshot,
  ParkingZone,
  ParkingLotSavedDetails,
  ParkingSpotSavedEndUserDetails,
  ParkingLocationSavedEndUserDetails,
} from "@/api/models";
import { ParkingSpotFlipFlopStatus } from "@/api/models/ParkingHistory";

const PARKING_STATUSES: Record<string, Record<string, string>> = {
  all_spots: { color: "blue", label: "Total Number of All Spots" },
  free: { color: "green", label: "Number of Available Spots" },
  unavailable: { color: "red", label: "Number of Unavailable Spots" },
  reserved: { color: "grey", label: "Number of Blocked Spots" },
};

const UNTRACKED_ROI_STATUSES: Record<string, Record<string, string>> = {
  roi_exit: { color: "green", label: "ROI Exit" },
  roi_entry: { color: "red", label: "ROI Entry" },
  vehicle_changed: { color: "red", label: "Vehicle Changed" },
  null: { color: "grey", label: "ROI Update" }, // Failsafe just incase value is null (unused)
};

const UNTRACKABLE_PARKING_STATUSES: Record<string, Record<string, string>> = {
  all_untrackable_spots: {
    color: "#9D9D9D",
    label: "Number of Untrackable Spots/Site Problem",
  },
  is_camera_not_assigned: {
    color: "#9D9D9D",
    label: "Camera not mapped",
  },
  is_status_unknown_camera_inactive: {
    color: "#9D9D9D",
    label: "Camera Inactive/Switched off",
  },
  is_status_unknown_camera_offline: {
    color: "#9D9D9D",
    label: "Camera Offline",
  },
  is_status_unknown_edge_device_offline: {
    color: "#9D9D9D",
    label: "Edge Device Offline",
  },
  is_status_marked_unknown: {
    color: "#9D9D9D",
    label: "Marked Unknown by Admin",
  },
};

const UNKNOWN_PARKING_STATUSES: Record<string, Record<string, string>> = {
  all_unknown_spots: { color: "yellow", label: "Number of Unknown Spots" },
  is_status_unknown_flip_flop: {
    color: "yellow",
    label: "Unstable Spot Status (Flip-Flop)",
  },
  is_status_unknown_parallel_parking: {
    color: "yellow",
    label: "Parallel Parking",
  },
};

interface ParkingSpotIdAndName {
  id: number;
  name?: string;
}

export default Vue.extend({
  name: "LotDashboard",

  components: {
    MapEditor,
    // DigiMapBoxes,  // Disable for now since it is not shown to users
  },

  data() {
    return {
      loadingImage: false,
      statsDataLastLoadedAt: null as string | null,
      view: "view",
      tab: "occupancy",
      breadcrumbItems: [
        {
          text: "Home",
          disabled: false,
          to: { name: "Home" },
        },
        {
          text: "DigiMap",
          disabled: true,
        },
      ],
      lotId: 0,
      selectedZone: null as null | ParkingZoneNames,
      zoneFilterLoading: false,
      selectedUntrackedZone: null as null | ParkingZoneNames,
      zones: [] as Array<ParkingZone>,
      allZonesNames: [] as Array<ParkingZoneNames>,
      untrackedZones: [] as Array<ParkingZoneNames>,
      savedSpots: [] as Array<ParkingSpotSavedEndUserDetails>,
      savedLocations: [] as Array<ParkingLocationSavedEndUserDetails>,
      untrackedZoneHeaders: [
        { text: "Zone Name", value: "name" },
        { text: "Capacity", value: "num_total_untracked_spots" },
        { text: "Busy", value: "busy" },
        { text: "Free", value: "num_free_untracked_spots" },
      ],
      occupancy: [] as Array<ParkingLotOccupancy>,
      statsFirstLoad: true,
      updates: null as PaginatedItems<ParkingHistory> | null,
      allCameraSpotCounts: [] as Array<CameraSpotCounts>,
      refinedCameraSpotCounts: [] as Array<CameraSpotCounts>,
      congested: false,
      PARKING_STATUSES: Object.freeze(PARKING_STATUSES) as Record<
        string,
        Record<string, string>
      >,
      UNTRACKED_ROI_STATUSES: Object.freeze(UNTRACKED_ROI_STATUSES) as Record<
        string,
        Record<string, string>
      >,
      UNKNOWN_PARKING_STATUSES: Object.freeze(
        UNKNOWN_PARKING_STATUSES
      ) as Record<string, Record<string, string>>,
      UNTRACKABLE_PARKING_STATUSES: Object.freeze(
        UNTRACKABLE_PARKING_STATUSES
      ) as Record<string, Record<string, string>>,
      autoRefreshIntervalId: null as number | null,
      updatesPagination: {
        page: 1,
        size: 10,
      },
      parkingLotData: null as ParkingLot | null,
      parkingPermits: {
        items: null as Array<ParkingPermit> | null,
        filterSelected: [] as Array<number>,
        snapshots: {
          create: {
            name: "",
            allFieldsValid: false,
            isLoading: false,
          },
          apply: {
            showIncompatibleConfirmPopup: false,
            missingSpotIds: [] as Array<number>,
            obsoleteSpotIds: [] as Array<number>,
            isCompatible: false,
            selectedSnapshot: null as ParkingLotParkingPermitsSnapshot | null,
          },
          items: [] as Array<ParkingLotParkingPermitsSnapshot> | null,
        },
      },
      activeTab: null as string | null,
      tabItems: [{ label: "Details", value: "details" }],
      showParkingSpotIds: false,
      showParkingSpotNames: false,
      showParkingSpotCameraIds: false,
      showDisplayBoards: true,
      showCameraFOVs: false,
      showParkingZoneNames: false,
      showCameraIcons: true,
      showLicensePlates: true,
      updatesFilters: {
        showUpdatesFilters: false,
        parkingSpots: {
          availableItems: [] as Array<ParkingSpotIdAndName>,
          selected: [] as Array<number>,
        },
        status: {
          all: true,
          spotUpdates: true,
          flipflopUpdates: true,
          unstableUpdates: process.env
            .VUE_APP_IS_FEATURE_UNSTABLE_UPDATES_ENABLED
            ? true
            : false,
          markedUnknown: true,
          roiUpdates: false,
        },
        cameras: {
          selected: [] as Array<number>,
          // Note, the list of cameraId items is generated dynamically based on
          // filter checkbox selection using the "cameraFilterItems" computed prop below.
        },
        zones: {
          selected: [] as Array<number>,
        },
        specialAreas: {
          selected: [] as Array<number>,
        },
        dateMenu: {
          showMenu: false,
          value: [] as Array<string>,
          startTime: {
            show: false,
            value: "",
          },
          endTime: {
            show: false,
            value: "",
          },
        },
        historyId: null as number | null,
        licensePlate: null as string | null,
        loading: false,
      },
      savedDetailsUpdatedAt: null as Date | null,
      viewPastSpotHistory: false,

      IS_FEATURE_UNSTABLE_UPDATES_ENABLED:
        process.env.VUE_APP_IS_FEATURE_UNSTABLE_UPDATES_ENABLED,
    };
  },

  created() {
    this.lotId = Number(this.$route.params.lotId);
  },

  async beforeMount() {
    // this.getParkingLotTimezone();
    // Fetch lesser data initially for occupancy sidebar since only the occupancy
    // tab is initially visible.
    this.fetchStatsOccupancy();
  },
  async mounted() {
    await this.fetchParkingLotData();

    await this.fetchSavedDetails();
    // show updates only for superadmin
    if (this.isSuperAdmin) {
      this.tabItems.push({ label: "Updates", value: "updates" });
    } else {
      // parking history visible should be set either on parking lot level, or should be set on both Org and lot level
      if (
        (this.parkingLotData &&
          this.parkingLotData.is_parking_history_visible) ||
        (this.getCurrentUserData &&
          this.getCurrentUserData.organization &&
          this.getCurrentUserData.organization.is_parking_history_visible &&
          this.parkingLotData &&
          this.parkingLotData.is_parking_history_visible)
      ) {
        this.tabItems.push({ label: "Updates", value: "updates" });
      }
    }
    // if parking permits feature is enabled only then show Permits tab
    if (
      this.hasAccessLevelDashboardMonitoring &&
      this.parkingLotData &&
      this.parkingLotData.is_parking_permit_feature_enabled
    ) {
      this.tabItems.push({ label: "Permits", value: "permits" });
    }

    // Display Spot update if history id or license plate is present in URL
    let show_history = false;
    const historyId = this.$route.query.history_id;
    if (historyId) {
      this.updatesFilters.historyId = parseInt(historyId as string);
      show_history = true;
    } else if (this.$route.query.license_plate) {
      this.updatesFilters.licensePlate = this.$route.query
        .license_plate as string;
      show_history = true;
    }
    if (show_history) {
      this.activeTab = "updates";
    }

    // check for Updates filter in roue query
    let timestamp = this.$route.query.timestamp;
    if (timestamp) {
      timestamp = timestamp as string;
      if (timestamp) {
        const localStartDateTime = dayjs(timestamp);
        this.updatesFilters.dateMenu.value.push(
          localStartDateTime.format("YYYY-MM-DD")
        );
        this.updatesFilters.dateMenu.startTime.value =
          localStartDateTime.format("HH:mm");

        const interval = this.$route.query.interval;
        if (interval) {
          const matchingInterval = parseInt(interval as string);
          if (matchingInterval != null) {
            const localEndDateTime = localStartDateTime.add(
              matchingInterval || 10,
              "minutes"
            );
            this.updatesFilters.dateMenu.value.push(
              localEndDateTime.format("YYYY-MM-DD")
            );
            this.updatesFilters.dateMenu.endTime.value =
              localEndDateTime.format("HH:mm");
          }
        }
        this.activeTab = "updates";
      }
    }

    // If lot does not have any spots, then enable roiUpdates by default
    if (this.parkingLotData && this.parkingLotData.parking_spots.length == 0) {
      this.updatesFilters.status.roiUpdates = true;
      this.updatesFilters.status.spotUpdates = false;
      this.updatesFilters.status.flipflopUpdates = false;
      this.updatesFilters.status.markedUnknown = false;
      this.updatesFilters.status.all = false;
    }

    await this.fetchStats();

    if (this.parkingLotData && this.parkingLotData.run_inference) {
      let loadingRefreshData = false;
      if (!this.viewPastSpotHistory) {
        this.autoRefreshIntervalId = setInterval(async () => {
          if (this.$route.name != "LotDashboard") return;
          if (loadingRefreshData) return;
          if (this.viewPastSpotHistory) return;
          // When browser tab is not visible (user has switched to another tab),
          // then do not poll api.
          if (document.hidden) return;
          if (this.updatesFilters.historyId || this.updatesFilters.licensePlate)
            return;
          loadingRefreshData = true;
          await this.fetchStats();
          loadingRefreshData = false;
        }, 4000);
      }
    }

    this.setMapToggles();
  },

  beforeDestroy() {
    this.clearRefreshInterval();
  },

  destroyed() {
    this.clearRefreshInterval();
  },

  methods: {
    ...mapActions("data", ["initCurrentParkingLotData"]),
    clearRefreshInterval() {
      if (this.autoRefreshIntervalId) {
        clearInterval(this.autoRefreshIntervalId);
      }
      this.autoRefreshIntervalId = null;
    },
    openVehicleRecord(vehicleRecordId: number) {
      const routeData = this.$router.resolve({
        name: "LotLprManagement",
        params: { lotId: String(this.lotId) },
        query: { anpr_record_id: String(vehicleRecordId) },
      });
      window.open(routeData.href, "_blank");
    },
    async fetchStats() {
      let selectedZoneIds =
        this.updatesFilters.zones.selected?.length > 0
          ? this.updatesFilters.zones.selected
          : this.selectedZone
          ? this.selectedZone.id > 0
            ? [this.selectedZone.id]
            : []
          : [];

      if (this.selectedZone) {
        if (this.selectedZone.zone_type == "time_limited_zone") {
          this.updatesFilters.status.all = false;
        } else {
          this.updatesFilters.status.all = true;
        }
        this.updatesFilters.status.spotUpdates = this.updatesFilters.status.all;
        this.updatesFilters.status.flipflopUpdates =
          this.updatesFilters.status.all;
        this.updatesFilters.status.unstableUpdates =
          this.updatesFilters.status.all;
        this.updatesFilters.status.markedUnknown =
          this.updatesFilters.status.all;
        this.updatesFilters.status.roiUpdates = !this.updatesFilters.status.all;
      }
      let apiRequestTime = new Date();
      let stats: ParkingLotStats | null = await api.getParkingLotStats(
        this.lotId,
        this.updatesPagination.page,
        this.updatesPagination.size,
        this.updatesFilters.status.spotUpdates,
        this.updatesFilters.status.flipflopUpdates,
        this.updatesFilters.status.unstableUpdates,
        this.updatesFilters.status.markedUnknown,
        this.updatesFilters.status.roiUpdates,
        this.updatesFilters.historyId,
        this.updatesFilters.licensePlate,
        this.updatesFilters.parkingSpots.selected,
        this.updatesFilters.cameras.selected,
        selectedZoneIds,
        this.updatesFilters.specialAreas.selected,
        this.updatesFilters.dateMenu.value,
        [
          this.updatesFilters.dateMenu.startTime.value,
          this.updatesFilters.dateMenu.endTime.value,
        ],
        this.statsDataLastLoadedAt
      );
      this.statsDataLastLoadedAt = apiRequestTime
        .toISOString()
        .replace("Z", "");
      if (stats) {
        this.updatesFilters.parkingSpots.availableItems = stats.spots
          .map(
            (spot) => ({ id: spot.id, name: spot.name } as ParkingSpotIdAndName)
          )
          .sort((a, b) => {
            const valA = this.showParkingSpotNames
              ? a.name
                ? parseInt(a.name)
                : a.id
              : a.id;
            const valB = this.showParkingSpotNames
              ? b.name
                ? parseInt(b.name)
                : a.id
              : b.id;
            return valA - valB;
          });

        this.zones = stats.zones;
        this.occupancy = stats.occupancy;
        this.updates = stats.updates;
        this.allCameraSpotCounts = stats.all_camera_spot_counts;
        this.refinedCameraSpotCounts = stats.refined_camera_spot_counts;
        this.congested = stats.congested;
        this.savedDetailsUpdatedAt = stats.saved_details_updated_at;

        if (this.updatesFilters.loading) {
          this.updatesFilters.loading = false;
        }
        this.statsFirstLoad = false;
        this.zoneFilterLoading = false;
      }
    },
    resetEtags() {
      /*
       * Clear Etag timestamp since stats API params may have changed.
       */
      this.statsDataLastLoadedAt = null;
    },
    async fetchStatsOccupancy() {
      let selectedZoneIds =
        this.updatesFilters.zones.selected?.length > 0
          ? this.updatesFilters.zones.selected
          : this.selectedZone
          ? [this.selectedZone.id]
          : [];
      let statsOccupancy: Array<ParkingLotOccupancy> | null =
        await api.getParkingLotStatsOccupancy(this.lotId, selectedZoneIds);
      if (statsOccupancy) {
        this.occupancy = statsOccupancy;
      }
      if (this.updatesFilters.loading) {
        this.updatesFilters.loading = false;
      }
    },
    async fetchSavedDetails() {
      let saved_details: ParkingLotSavedDetails | null =
        await api.getParkingLotSavedDetails(this.lotId);
      if (saved_details) {
        this.savedSpots = saved_details.saved_spots;
        this.savedLocations = saved_details.saved_locations;
      }
    },
    async fetchParkingLotData() {
      this.parkingLotData = await api.getParkingLot(this.lotId);
      // if parking lot does not exist or not accessible then reroute to home page
      if (this.parkingLotData == null) {
        this.$dialog.message.error(
          "Parking Lot does not exist or Access denied.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
        window.open(`${process.env.VUE_APP_3_BASE_URL_PATH}/home`, "_self");
      }
      this.initCurrentParkingLotData(this.parkingLotData);
      if (this.parkingLotData) {
        localStorage.setItem("currentLotName", this.parkingLotData.name);
        window.dispatchEvent(
          new CustomEvent("lot-name-changed", {
            detail: {
              lot_name: localStorage.getItem("currentLotName"),
            },
          })
        );

        this.breadcrumbItems[0].text = this.parkingLotData.name;
        this.breadcrumbItems[0].to = {
          name: "LotDashboard",
        };
      }
      if (this.parkingLotData && this.parkingLotData?.parking_zones) {
        this.allZonesNames = [
          { name: "All Zones", id: 0, zone_type: "" },
          // Hide Only spot tracking & only car counting since they conflict with story 4760 nested zones
          // { name: "Only Spot Tracking Zones", id: -1 },
          // { name: "Only Car Counting Zones", id: -2 },
          ...this.parkingLotData?.parking_zones
            .filter(
              (zone) =>
                zone.zone_type != "time_limited_zone" &&
                zone.is_level == false &&
                zone.is_multi_level_structure == false
            )
            .map(
              (zone) =>
                ({
                  id: zone.id,
                  name: zone.name,
                  zone_type: zone.zone_type,
                } as ParkingZoneNames)
            ),
        ];

        // Add multi-level zones and levels grouped together in zones filter dropdown
        if (this.parkingLotData.parking_zones) {
          let multilevelStructureZones =
            this.parkingLotData.parking_zones.filter(
              (z) => z.is_multi_level_structure
            );
          for (let zone of multilevelStructureZones) {
            this.allZonesNames.push({
              id: zone.id,
              name: zone.name,
              zone_type: zone.zone_type,
            } as ParkingZoneNames);
            let levels = this.parkingLotData.parking_zones
              .filter((z) => z.nested_parent_zone_id == zone.id && z.is_level)
              .sort((l1, l2) => l1.id - l2.id);
            for (let level of levels) {
              this.allZonesNames.push({
                id: level.id,
                name: "    - " + level.name,
                zone_type: level.zone_type,
              } as ParkingZoneNames);
            }
          }
        }

        this.untrackedZones = [
          ...this.parkingLotData?.parking_zones
            .filter(
              (zone) =>
                zone.is_untracked &&
                !zone.is_multi_level_structure &&
                !zone.is_level
            )
            .map(
              (zone) =>
                ({
                  id: zone.id,
                  name: zone.name,
                } as ParkingZoneNames)
            ),
        ];
      }

      this.parkingPermits.items = await api.getAllParkingPermits(this.lotId);
      // remove 'Privilege Permit' from the list
      if (this.parkingPermits.items) {
        this.parkingPermits.items = this.parkingPermits.items.filter(
          (permit) => permit.name != "Privilege Permit"
        );
      }
      this.parkingPermits.snapshots.items =
        await api.getAllParkingPermitSnapshots(this.lotId);
    },
    async savePermitsSnapshot() {
      let permitSnapshot = await api.createParkingPermitSnapshot(
        this.lotId,
        this.parkingPermits.snapshots.create.name
      );
      this.parkingPermits.snapshots.create.name = "";
      this.parkingPermits.snapshots.items =
        await api.getAllParkingPermitSnapshots(this.lotId);
    },
    checkPermitsSnapshotCompatibility(
      snapshotMapping: Record<string, Array<number>>
    ) {
      // Check if the snapshot has the same spots as the current parking lot digimap
      let snapshotSpotIdExistsInCurrentMap: Record<string, boolean> = {};
      for (let spotId of Object.keys(snapshotMapping)) {
        snapshotSpotIdExistsInCurrentMap[spotId] = false;
      }
      let spotIdsMissingInSnapshot = [];
      if (this.parkingLotData?.parking_spots) {
        for (let spot of this.parkingLotData?.parking_spots) {
          if (spot.id in snapshotMapping) {
            snapshotSpotIdExistsInCurrentMap[spot.id] = true;
          } else {
            spotIdsMissingInSnapshot.push(spot.id);
          }
        }
      }
      let obsoleteSpotIdsInSnapshot = Object.keys(snapshotMapping)
        .filter((id) => !snapshotSpotIdExistsInCurrentMap[id])
        .map((id) => Number(id));
      this.parkingPermits.snapshots.apply.missingSpotIds =
        spotIdsMissingInSnapshot;
      this.parkingPermits.snapshots.apply.obsoleteSpotIds =
        obsoleteSpotIdsInSnapshot;
      this.parkingPermits.snapshots.apply.isCompatible =
        spotIdsMissingInSnapshot.length == 0 &&
        obsoleteSpotIdsInSnapshot.length == 0;
    },
    async applyPermitsSnapshot(
      snapshot: ParkingLotParkingPermitsSnapshot,
      confirmIncompatible = false
    ) {
      console.log("Applying permits snapshot", snapshot);
      if (snapshot.snapshot_json != null) {
        this.checkPermitsSnapshotCompatibility(snapshot.snapshot_json);
        if (
          this.parkingPermits.snapshots.apply.isCompatible ||
          confirmIncompatible
        ) {
          let success = api.applyParkingPermitSnapshot(this.lotId, snapshot.id);
          this.$router.go(0); // Reload page to redraw map
        } else {
          this.parkingPermits.snapshots.apply.selectedSnapshot = snapshot;
          this.parkingPermits.snapshots.apply.showIncompatibleConfirmPopup =
            true;
        }
      }
    },
    cancelApplyPermitsSnapshot() {
      this.parkingPermits.snapshots.apply.showIncompatibleConfirmPopup = false;
      this.parkingPermits.snapshots.apply.missingSpotIds = [];
      this.parkingPermits.snapshots.apply.obsoleteSpotIds = [];
      this.parkingPermits.snapshots.apply.isCompatible = false;
      this.parkingPermits.snapshots.apply.selectedSnapshot = null;
    },
    checkIfUpdatesFilterDateValid() {
      if (
        this.updatesFilters.dateMenu.showMenu &&
        this.updatesFilters.dateMenu.value &&
        this.updatesFilters.dateMenu.value.length >= 2
      ) {
        this.updatesFilters.dateMenu.showMenu = false;
        this.updatesFilters.dateMenu.startTime.value = "00:00";
        this.updatesFilters.dateMenu.endTime.value = "23:59";
      }
    },
    clearUpdatesFilters() {
      this.updatesFilters.parkingSpots.selected = [];
      this.updatesFilters.cameras.selected = [];
      this.updatesFilters.zones.selected = [];
      this.updatesFilters.specialAreas.selected = [];
      this.updatesFilters.status.roiUpdates = false;
      this.updatesFilters.status.all = true;
      this.updatesFilters.status.spotUpdates = true;
      this.updatesFilters.status.flipflopUpdates = true;
      this.updatesFilters.status.unstableUpdates = true;
      this.updatesFilters.status.markedUnknown = true;
      this.updatesFilters.dateMenu.value = [];
      this.updatesFilters.showUpdatesFilters = false;
      this.updatesFilters.loading = true;
      this.updatesFilters.dateMenu.startTime.value = "00:00";
      this.updatesFilters.dateMenu.endTime.value = "23:59";
      this.updatesFilters.historyId = null;
      this.updatesFilters.licensePlate = null;
      this.updatesPagination.page = 1;
      this.updatesPagination.size = 10;

      this.resetEtags();
      this.fetchStats();
    },
    changeZoneFilter(zoneId: number) {
      let zoneName = this.allZonesNames.find((z) => z.id === zoneId);
      if (zoneName) {
        this.selectedZone = zoneName;
        this.zoneFilterLoading = true;
        this.resetEtags();
        this.fetchStats();
      }
    },
    zoneFilterChanged() {
      this.zoneFilterLoading = true;
      this.resetEtags();
    },
    applyUpdatesFilters() {
      this.updatesPagination.page = 1;
      this.updatesPagination.size = 10;
      this.updatesFilters.showUpdatesFilters = false;
      this.updatesFilters.loading = true;

      this.resetEtags();
      this.fetchStats();
    },
    // convert 24 hour time to 12 hour time format
    timeConvert(time: string) {
      return new Date("1970-01-01T" + time + "Z").toLocaleTimeString("en-US", {
        timeZone: "UTC",
        hour12: true,
        hour: "numeric",
        minute: "numeric",
      });
    },

    // set map toggles from values in localstorage
    setMapToggles() {
      let showSpotsLS = localStorage.getItem("showSpots");
      if (showSpotsLS && this.getCurrentUserData && this.parkingLotData) {
        showSpotsLS = JSON.parse(showSpotsLS);
        if (
          showSpotsLS &&
          showSpotsLS[this.getCurrentUserData.id] &&
          showSpotsLS[this.getCurrentUserData.id][this.parkingLotData?.id]
        ) {
          this.showParkingSpotIds = /true/i.test(
            showSpotsLS[this.getCurrentUserData.id][this.parkingLotData?.id]
          );
        }
      }

      let showSpotNamesLS = localStorage.getItem("showSpotNames");
      if (showSpotNamesLS && this.getCurrentUserData && this.parkingLotData) {
        showSpotNamesLS = JSON.parse(showSpotNamesLS);
        if (
          showSpotNamesLS &&
          showSpotNamesLS[this.getCurrentUserData.id] &&
          showSpotNamesLS[this.getCurrentUserData.id][this.parkingLotData?.id]
        ) {
          this.showParkingSpotNames = /true/i.test(
            showSpotNamesLS[this.getCurrentUserData.id][this.parkingLotData?.id]
          );
        }
      }

      let showCamerasLS = localStorage.getItem("showCameras");
      if (showCamerasLS && this.getCurrentUserData && this.parkingLotData) {
        showCamerasLS = JSON.parse(showCamerasLS);
        if (
          showCamerasLS &&
          showCamerasLS[this.getCurrentUserData.id] &&
          showCamerasLS[this.getCurrentUserData.id][this.parkingLotData?.id]
        ) {
          this.showParkingSpotCameraIds = /true/i.test(
            showCamerasLS[this.getCurrentUserData.id][this.parkingLotData?.id]
          );
        }
      }

      let showDisplayBoardsLS = localStorage.getItem("showDisplayBoards");
      if (
        showDisplayBoardsLS &&
        this.getCurrentUserData &&
        this.parkingLotData
      ) {
        showDisplayBoardsLS = JSON.parse(showDisplayBoardsLS);
        if (
          showDisplayBoardsLS &&
          showDisplayBoardsLS[this.getCurrentUserData.id] &&
          showDisplayBoardsLS[this.getCurrentUserData.id][
            this.parkingLotData?.id
          ]
        ) {
          this.showDisplayBoards = /true/i.test(
            showDisplayBoardsLS[this.getCurrentUserData.id][
              this.parkingLotData?.id
            ]
          );
        }
      }

      let showZonesLS = localStorage.getItem("showZones");
      if (showZonesLS && this.getCurrentUserData && this.parkingLotData) {
        showZonesLS = JSON.parse(showZonesLS);
        if (
          showZonesLS &&
          showZonesLS[this.getCurrentUserData.id] &&
          showZonesLS[this.getCurrentUserData.id][this.parkingLotData?.id]
        ) {
          this.showParkingZoneNames = /true/i.test(
            showZonesLS[this.getCurrentUserData.id][this.parkingLotData?.id]
          );
        }
      }

      let showCameraFOVLS = localStorage.getItem("showCameraFOV");
      if (showCameraFOVLS && this.getCurrentUserData && this.parkingLotData) {
        showCameraFOVLS = JSON.parse(showCameraFOVLS);
        if (
          showCameraFOVLS &&
          showCameraFOVLS[this.getCurrentUserData.id] &&
          showCameraFOVLS[this.getCurrentUserData.id][this.parkingLotData?.id]
        ) {
          this.showCameraFOVs = /true/i.test(
            showCameraFOVLS[this.getCurrentUserData.id][this.parkingLotData?.id]
          );
        }
      }

      let showCameraIconsLS = localStorage.getItem("showCameraIcons");
      if (showCameraIconsLS && this.getCurrentUserData && this.parkingLotData) {
        showCameraIconsLS = JSON.parse(showCameraIconsLS);
        if (
          showCameraIconsLS &&
          showCameraIconsLS[this.getCurrentUserData.id] &&
          showCameraIconsLS[this.getCurrentUserData.id][this.parkingLotData?.id]
        ) {
          this.showCameraIcons = /true/i.test(
            showCameraIconsLS[this.getCurrentUserData.id][
              this.parkingLotData?.id
            ]
          );
        }
      }

      let showLicensePlatesLS = localStorage.getItem("showLicensePlates");
      if (
        showLicensePlatesLS &&
        this.getCurrentUserData &&
        this.parkingLotData
      ) {
        showLicensePlatesLS = JSON.parse(showLicensePlatesLS);
        if (
          showLicensePlatesLS &&
          showLicensePlatesLS[this.getCurrentUserData.id] &&
          showLicensePlatesLS[this.getCurrentUserData.id][
            this.parkingLotData?.id
          ]
        ) {
          this.showLicensePlates = /true/i.test(
            showLicensePlatesLS[this.getCurrentUserData.id][
              this.parkingLotData?.id
            ]
          );
        }
      }
    },

    // Update localStorage map toggles
    updateMapTogglesLocalStorage(show: boolean, toggle: string) {
      if (this.getCurrentUserData && this.parkingLotData) {
        let showSpotsData = null;
        let showSpotsLS = localStorage.getItem(toggle);
        if (showSpotsLS) {
          showSpotsData = JSON.parse(showSpotsLS);
          if (!showSpotsData[this.getCurrentUserData.id]) {
            showSpotsData[this.getCurrentUserData.id] = {
              [this.parkingLotData?.id]: false,
            };
          }
        } else {
          showSpotsData = {
            [this.getCurrentUserData.id]: {
              [this.parkingLotData?.id]: false,
            },
          };
        }

        if (show) {
          showSpotsData[this.getCurrentUserData.id][this.parkingLotData?.id] =
            true;
        } else {
          showSpotsData[this.getCurrentUserData.id][this.parkingLotData?.id] =
            false;
        }

        localStorage.setItem(toggle, JSON.stringify(showSpotsData));
      }
    },

    secondsToTime(seconds: number) {
      seconds = Number(seconds);
      const d = Math.floor(seconds / 3600 / 24);
      const h = Math.floor((seconds / 3600) % 24);
      const m = Math.floor((seconds % 3600) / 60);
      const s = Math.floor((seconds % 3600) % 60);
      let ms = Math.round((seconds - Math.floor(seconds)) * 1000);
      ms = s > 0 ? 0 : ms;

      const dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
      const hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
      const mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
      const sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
      const millisDisplay =
        s <= 0 && ms > 0
          ? "0 second" /*ms + (ms == 1 ? " millisecond" : " milliseconds")*/
          : "";
      return dDisplay + hDisplay + mDisplay + sDisplay + millisDisplay;
    },

    getUpdatesHoverText(item: ParkingHistory) {
      if (item) {
        if (
          item.flipflop_status &&
          item.flipflop_status == ParkingSpotFlipFlopStatus.flipflop_activated
        ) {
          return `Flipflop activated at ${item.created_at}`;
        } else if (
          item.flipflop_status &&
          item.flipflop_status == ParkingSpotFlipFlopStatus.flipflop_deactivated
        ) {
          return `Flipflop deactivated at ${
            item.created_at
          } and was active for ${this.secondsToTime(item.status_change_time)}`;
        }
      }
    },

    async blobToBase64(blob: Blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    },
    async fetchAuthenticatedImage(imageUrl: string) {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          return "";
        }
        const response = await fetch(imageUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const blob = await response.blob();
        const base64_image = await this.blobToBase64(blob);
        return base64_image as string;
      } catch (error) {
        console.error("Error fetching image:", error);
        return "";
      }
    },
    show(image_url: string) {
      this.$viewerApi({
        options: {
          initialViewIndex: 0,
          focus: false,
          button: false,
        },
        images: [
          {
            src: image_url,
            alt: "Image",
          },
        ],
      });
    },
    async showUpdateImage(updateItem: ParkingHistory) {
      if (this.isSuperAdmin) {
        if (updateItem.image_path_url) {
          window.open(updateItem.image_path_url, "_blank");
        }
        return;
      }
      if (updateItem.clean_image_url) {
        this.loadingImage = true;
        const clean_image_url = await this.fetchAuthenticatedImage(
          updateItem.clean_image_url
        );
        this.loadingImage = false;
        if (clean_image_url) {
          this.show(clean_image_url);
        }
      }
    },

    setUpdatesStatusFilter() {
      this.updatesFilters.status.spotUpdates = this.updatesFilters.status.all;
      this.updatesFilters.status.flipflopUpdates =
        this.updatesFilters.status.all;
      this.updatesFilters.status.unstableUpdates =
        this.updatesFilters.status.all;
      this.updatesFilters.status.markedUnknown = this.updatesFilters.status.all;
      this.updatesFilters.status.roiUpdates = !this.updatesFilters.status.all;
    },

    setUnsetUpdatesStatusFilterOnSelection() {
      this.resetEtags();
      if (
        (this.updatesFilters.status.spotUpdates ||
          this.updatesFilters.status.flipflopUpdates ||
          this.updatesFilters.status.markedUnknown) &&
        this.updatesFilters.status.roiUpdates
      ) {
        this.updatesFilters.status.roiUpdates = false;
        this.updatesFilters.zones.selected = [];
      }
      if (
        this.updatesFilters.status.spotUpdates &&
        this.updatesFilters.status.flipflopUpdates &&
        this.updatesFilters.status.unstableUpdates &&
        this.updatesFilters.status.markedUnknown
      ) {
        this.updatesFilters.status.all = true;
        this.updatesFilters.zones.selected = [];
      } else {
        this.updatesFilters.status.all = false;
        this.updatesFilters.zones.selected = [];
      }
      if (
        !(
          this.updatesFilters.status.spotUpdates ||
          this.updatesFilters.status.flipflopUpdates ||
          this.updatesFilters.status.markedUnknown
        ) &&
        !this.updatesFilters.status.roiUpdates
      ) {
        Vue.nextTick(() => {
          this.updatesFilters.status.all = true;
          this.updatesFilters.status.spotUpdates = true;
          this.updatesFilters.status.flipflopUpdates = true;
          this.updatesFilters.status.markedUnknown = true;
        });
      }
    },

    setUnsetRoiUpdatesStatusFilterOnSelection() {
      /* Allow enabling only either spot updates or roi updates, but not both */

      this.resetEtags();

      if (this.updatesFilters.status.roiUpdates) {
        this.updatesFilters.status.spotUpdates = false;
        this.updatesFilters.status.flipflopUpdates = false;
        this.updatesFilters.status.markedUnknown = false;
        this.updatesFilters.status.all = false;
        this.updatesFilters.parkingSpots.selected = [];
      } else if (
        !(
          this.updatesFilters.status.spotUpdates ||
          this.updatesFilters.status.flipflopUpdates ||
          this.updatesFilters.status.markedUnknown
        )
      ) {
        this.updatesFilters.status.spotUpdates = true;
        this.updatesFilters.status.flipflopUpdates = true;
        this.updatesFilters.status.markedUnknown = true;
        this.updatesFilters.status.all = true;
        this.updatesFilters.zones.selected = [];
      }
    },

    updatesFiltersSetEndTime(dateVal: string, timeVal: string) {
      this.updatesFilters.dateMenu.startTime.value = "00:00";
      this.updatesFilters.dateMenu.endTime.value = timeVal;
      if (this.parkingLotData && this.parkingLotData.created_at) {
        this.updatesFilters.dateMenu.value[0] = this.parkingLotData.created_at
          .toString()
          .split("T")[0];
      } else {
        this.updatesFilters.dateMenu.value[0] = dateVal;
      }
      this.updatesFilters.dateMenu.value[1] = dateVal;
      this.applyUpdatesFilters();
    },

    removeZoneFilter() {
      this.selectedZone = null;
      this.zoneFilterLoading = true;
    },

    getFormattedDateTime(dateTime: string) {
      let dayObj = dayjs.utc(dateTime).local();
      const selectedTimezone = localStorage.getItem("selected_timezone");
      if (selectedTimezone) {
        dayObj = dayjs.utc(dateTime).tz(selectedTimezone);
      }
      const selectedTimeFormat = localStorage.getItem("time_format_option");
      let formattedDate = dayObj.format("M/D/YYYY, h:mm:ss A");
      if (selectedTimeFormat === "24_hr") {
        formattedDate = dayObj.format("M/D/YYYY, HH:mm:ss");
      }

      let tz_short = "";
      if (selectedTimezone) {
        const locale = navigator.language ? navigator.language : "en-US";
        let timezone_short = new Intl.DateTimeFormat(locale, {
          timeZone: selectedTimezone,
          timeZoneName: "long",
        })
          .formatToParts(new Date())
          .find((part) => part.type === "timeZoneName")?.value;
        if (timezone_short) {
          // abbreviate text
          timezone_short = timezone_short
            .split(" ")
            .map((word) => word[0])
            .join("");
          tz_short = `(${timezone_short})`;
        } else {
          tz_short = dayjs().tz(selectedTimezone).format("(z)");
        }
      }

      return formattedDate + " " + tz_short;
    },

    async getParkingLotTimezone() {
      const is_lot_timezone = localStorage.getItem("timezone_option");

      if (is_lot_timezone && is_lot_timezone == "local_lot_timezone") {
        let parking_lot_timezone = await api.getParkingLotTimezone(this.lotId);
        const current_timezone = localStorage.getItem("selected_timezone");
        if (parking_lot_timezone && parking_lot_timezone != current_timezone) {
          localStorage.setItem("selected_timezone", parking_lot_timezone);
          this.$router.go(0);
        } else if (!current_timezone) {
          localStorage.setItem(
            "selected_timezone",
            Intl.DateTimeFormat().resolvedOptions().timeZone
          );
        }
      }
    },
  },

  computed: {
    ...mapGetters("user", [
      "isSuperAdmin",
      "isAdmin",
      "getCurrentUserData",
      "hasAccessLevelDashboardMonitoring",
    ]),

    allOccupancies(): Array<Record<string, string | number>> {
      let totalCounts = 0; // Total number of spots (having any current_status)
      let allSpotsOcc = this.occupancy.find(
        (o) => o.current_status === "all_spots"
      );
      if (allSpotsOcc) {
        totalCounts = allSpotsOcc.count;
      } else {
        for (const occ of this.occupancy) {
          totalCounts += occ.count;
        }
      }
      let allOccupanciesList = [];
      for (let parkingStatusName in this.PARKING_STATUSES) {
        let occ = this.occupancy.find(
          (o: ParkingLotOccupancy) => o.current_status === parkingStatusName
        );
        if (!occ) {
          occ = {
            current_status: parkingStatusName,
            count: 0,
          } as ParkingLotOccupancy;
        }
        // rename 'reserved' to 'blocked' in ui on Occupancy Card
        occ.current_status =
          occ.current_status === "reserved" ? "blocked" : occ.current_status;
        allOccupanciesList.push({
          label: this.PARKING_STATUSES[parkingStatusName].label,
          count: occ.count,
          percent:
            totalCounts != 0 ? Math.round((occ.count / totalCounts) * 100) : 0,
          color: `${this.PARKING_STATUSES[parkingStatusName].color} lighten-4`,
          icon: occ.current_status === "blocked" ? "mdi-block-helper" : "",
        });
      }
      return allOccupanciesList;
    },

    untrackableOccupancies(): Array<Record<string, string | number>> {
      let totalCounts = 0; // Total number of spots (having any current_status)
      let allOccupanciesList = [];
      let allSpotsOcc = this.occupancy.find(
        (o) => o.current_status === "all_spots"
      );
      if (allSpotsOcc) {
        totalCounts = allSpotsOcc.count;
      } else {
        for (const occ of this.occupancy) {
          totalCounts += occ.count;
        }
      }
      for (let parkingStatusName in this.UNTRACKABLE_PARKING_STATUSES) {
        let occ = this.occupancy.find(
          (o: ParkingLotOccupancy) => o.current_status === parkingStatusName
        );
        if (!occ) {
          occ = {
            current_status: parkingStatusName,
            count: 0,
          } as ParkingLotOccupancy;
        }
        // rename 'reserved' to 'blocked' in ui on Occupancy Card
        occ.current_status =
          occ.current_status === "reserved" ? "blocked" : occ.current_status;
        allOccupanciesList.push({
          label: this.UNTRACKABLE_PARKING_STATUSES[parkingStatusName].label,
          count: occ.count,
          percent:
            totalCounts != 0 ? Math.round((occ.count / totalCounts) * 100) : 0,
          color:
            this.parkingLotData &&
            !this.parkingLotData.is_unknown_perception_feature_enabled
              ? `${this.UNKNOWN_PARKING_STATUSES["all_unknown_spots"].color} lighten-4`
              : `${this.UNTRACKABLE_PARKING_STATUSES[parkingStatusName].color}`,
        });
      }
      return allOccupanciesList;
    },

    unknownOccupancies(): Array<Record<string, string | number>> {
      let totalCounts = 0; // Total number of spots (having any current_status)
      let allOccupanciesList = [];
      let allSpotsOcc = this.occupancy.find(
        (o) => o.current_status === "all_spots"
      );
      if (allSpotsOcc) {
        totalCounts = allSpotsOcc.count;
      } else {
        for (const occ of this.occupancy) {
          totalCounts += occ.count;
        }
      }
      for (let parkingStatusName in this.UNKNOWN_PARKING_STATUSES) {
        let occ = this.occupancy.find(
          (o: ParkingLotOccupancy) => o.current_status === parkingStatusName
        );
        if (!occ) {
          occ = {
            current_status: parkingStatusName,
            count: 0,
          } as ParkingLotOccupancy;
        }
        // rename 'reserved' to 'blocked' in ui on Occupancy Card
        occ.current_status =
          occ.current_status === "reserved" ? "blocked" : occ.current_status;
        allOccupanciesList.push({
          label: this.UNKNOWN_PARKING_STATUSES[parkingStatusName].label,
          count: occ.count,
          percent:
            totalCounts != 0 ? Math.round((occ.count / totalCounts) * 100) : 0,
          color: `${this.UNKNOWN_PARKING_STATUSES[parkingStatusName].color} lighten-4`,
          icon: parkingStatusName === "all_unknown_spots" ? "mdi-help" : "",
        });
      }
      return allOccupanciesList;
    },

    allCameraRefinedSpotCounts(): Record<string, Record<string, number>> {
      let allCameraRefinedSpotCounts = {} as Record<
        string,
        Record<string, number>
      >;

      // All spot counts
      for (let { camera_id, count } of this.allCameraSpotCounts) {
        const label = camera_id ? `Camera ${camera_id}` : "Unassigned Camera";
        if (!(label in allCameraRefinedSpotCounts)) {
          allCameraRefinedSpotCounts[label] = {} as Record<string, number>;
        }
        allCameraRefinedSpotCounts[label]["allCounts"] = count;
        allCameraRefinedSpotCounts[label]["refinedCounts"] = 0;
      }
      // Refined spot counts
      for (let { camera_id, count } of this.refinedCameraSpotCounts) {
        const label = camera_id ? `Camera ${camera_id}` : "Unassigned Camera";
        if (!(label in allCameraRefinedSpotCounts)) {
          allCameraRefinedSpotCounts[label] = {};
        }
        allCameraRefinedSpotCounts[label]["refinedCounts"] = count;
      }

      // Percentage of count of refined spots
      for (let label in allCameraRefinedSpotCounts) {
        allCameraRefinedSpotCounts[label]["percent"] = Math.round(
          (allCameraRefinedSpotCounts[label]["refinedCounts"] /
            allCameraRefinedSpotCounts[label]["allCounts"]) *
            100
        );
      }

      return allCameraRefinedSpotCounts;
    },

    /**
     * Return list of spot IDs that require the same parking permit IDs assigned to
     * them as the permit IDs in this.parkingPermits.filterSelected list.
     */
    filteredSpotIds(): Array<number> | null {
      if (this.parkingPermits.filterSelected.length == 0) {
        return null;
      }
      let spotIdsMatchingSelectedPermits = [];
      if (this.parkingLotData?.parking_spots) {
        for (let parkingSpot of this.parkingLotData?.parking_spots) {
          if (
            _.intersection(
              parkingSpot.requires_parking_permit_ids,
              this.parkingPermits.filterSelected
            ).length > 0
          ) {
            spotIdsMatchingSelectedPermits.push(parkingSpot.id);
          }
        }
      }
      return spotIdsMatchingSelectedPermits;
    },

    allUntrackedZones(): Array<ParkingZone> {
      if (this.parkingLotData && this.parkingLotData?.parking_zones) {
        if (this.selectedUntrackedZone) {
          return this.zones.filter((zone) => {
            if (
              this.selectedUntrackedZone &&
              zone.id == this.selectedUntrackedZone.id
            ) {
              return true;
            }
            return false;
          });
        }
        return this.zones.filter(
          (zone) => zone.is_untracked && zone.zone_type != "time_limited_zone"
        );
      }
      return [];
    },

    onlyUntrackedZones(): Array<ParkingZoneNames> {
      /**
       * Excludes time limited parking zones (pickup/dropoff) zones from
       * untracked zones list.
       */
      return this.untrackedZones.filter((zoneItem) => {
        let zoneObj = this.parkingLotData?.parking_zones?.find(
          (z) => z.id === zoneItem.id
        );
        if (zoneObj?.zone_type === "time_limited_zone") {
          return false;
        } else {
          return true;
        }
      });
    },

    onlyDropOffPickUpZones(): Array<ParkingZoneNames> {
      /**
       * Excludes time limited parking zones (pickup/dropoff) zones from
       * untracked zones list.
       */
      return this.untrackedZones.filter((zoneItem) => {
        let zoneObj = this.parkingLotData?.parking_zones?.find(
          (z) => z.id === zoneItem.id
        );
        if (zoneObj?.zone_type === "time_limited_zone") {
          return true;
        } else {
          return false;
        }
      });
    },

    cameraFilterItems(): Array<number> {
      let cameraIds: Array<number> = [];
      if (this.parkingLotData?.cameras) {
        for (let camera of this.parkingLotData?.cameras) {
          if (this.updatesFilters.status.roiUpdates) {
            if (
              camera.untracked_zone_id ||
              camera.counting_zone_id ||
              camera.adjacent_zone_id
            ) {
              cameraIds.push(camera.id);
            }
          } else {
            if (
              !(
                camera.untracked_zone_id ||
                camera.counting_zone_id ||
                camera.adjacent_zone_id
              )
            ) {
              cameraIds.push(camera.id);
            }
          }
        }
      }
      return cameraIds;
    },
  },

  watch: {
    showParkingSpotNames(showSpots) {
      this.updateMapTogglesLocalStorage(showSpots, "showSpotNames");
    },
    showParkingSpotIds(showSpots) {
      this.updateMapTogglesLocalStorage(showSpots, "showSpots");
    },
    showParkingSpotCameraIds(showCameras) {
      this.updateMapTogglesLocalStorage(showCameras, "showCameras");
    },
    showDisplayBoards(showDisplayBoards) {
      this.updateMapTogglesLocalStorage(showDisplayBoards, "showDisplayBoards");
    },
    showParkingZoneNames(showZones) {
      this.updateMapTogglesLocalStorage(showZones, "showZones");
    },
    showCameraFOVs(showFovs) {
      this.updateMapTogglesLocalStorage(showFovs, "showCameraFOV");
    },
    showCameraIcons(showCameraIcons) {
      if (!showCameraIcons) {
        this.showParkingSpotCameraIds = false;
        this.showCameraFOVs = false;
      }
      this.updateMapTogglesLocalStorage(showCameraIcons, "showCameraIcons");
    },
    showLicensePlates(showLicensePlates) {
      this.updateMapTogglesLocalStorage(showLicensePlates, "showLicensePlates");
    },
    savedDetailsUpdatedAt(oldValue, newValue) {
      this.fetchSavedDetails();
    },
    selectedZone() {
      this.resetEtags();
    },
    "updatesPagination.page"() {
      this.updatesFilters.loading = true;
      this.resetEtags();
      this.fetchStats();
    },
    "updatesFilters.status.spotUpdates"() {
      this.setUnsetUpdatesStatusFilterOnSelection();
    },
    "updatesFilters.status.flipflopUpdates"() {
      this.setUnsetUpdatesStatusFilterOnSelection();
    },

    "updatesFilters.status.unstableUpdates"() {
      this.setUnsetUpdatesStatusFilterOnSelection();
    },
    "updatesFilters.status.markedUnknown"() {
      this.setUnsetUpdatesStatusFilterOnSelection();
    },
    "updatesFilters.status.roiUpdates"() {
      this.setUnsetRoiUpdatesStatusFilterOnSelection();
    },
    "updatesFilters.parkingSpots.selected"() {
      this.resetEtags();
    },
    "updatesFilters.zones.selected"() {
      this.resetEtags();
    },
    "updatesFilters.specialAreas.selected"() {
      this.resetEtags();
    },
    "updatesFilters.cameras.selected"() {
      this.resetEtags();
    },
    "updatesFilters.dateMenu.value"() {
      this.resetEtags();
    },
    "updatesFilters.dateMenu.startTime.value"() {
      this.resetEtags();
    },
    "updatesFilters.dateMenu.endTime.value"() {
      this.resetEtags();
    },
    viewPastSpotHistory() {
      this.resetEtags();
    },
  },
});
