






























































































































































































import Vue from "vue";
import api from "@/api/api";
import { mapGetters } from "vuex";
import {
  OrganizationUserCreate,
  OrganizationUserUpdate,
  OrganizationUserType,
  OrganizationCustomerUserType,
  OrganizationUser,
  AlertTypes,
  ParkingLotBasic,
} from "@/api/models";
import { has } from "lodash";

type VForm = Vue & { resetValidation: () => boolean };

export default Vue.extend({
  name: "OrgUserForm",

  props: {
    orgUserId: {
      type: Number,
    },
    orgId: {
      type: Number,
    },
    existingOrgUserDetails: {
      type: Object,
      required: false,
    },
    isChangePassword: {
      type: Boolean,
      required: false,
      default: false,
    },
    isEmail: {
      type: Boolean,
      required: false,
      default: false,
    },
    needsInit: {
      type: Boolean,
      required: false,
      default: false,
    },
    superAdminOrgId: {
      type: Number,
      required: false,
      default: process.env.VUE_APP_SUPERADMIN_ORG_ID,
    },
    technicianOrgId: {
      type: Number,
      required: false,
      default: process.env.VUE_APP_TECHNICIAN_ORG_ID,
    },
  },

  data: () => ({
    allFieldsValid: false,
    oldPasswordRulesData: [
      (v: string | null) =>
        (v && v.length >= 6) || "Password must have at least 6 characters.",
    ],
    passwordRulesData: [
      (v: string | null) => !!v || "Password is required",
      (v: string | null) =>
        (v && v.length >= 8) || "Password must be at least 8 characters",
      (v: string | null) =>
        (v &&
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&!])[A-Za-z\d@#$%^&!]+$/g.test(
            v
          )) ||
        "Password must include at least one lowercase letter, one uppercase letter, one numerical value, and one special character (! @ # $ % ^ &)",
    ],

    showPassword: false,
    showNewPassword: false,
    name: null as string | null,
    address: null as string | null,
    email: null as string | null,
    password: null as string | null,
    newPassword: null as string | null,
    orgUserType: null as string | null,
    receiveAlertEmails: false,
    isEmailPreferences: false,
    emailPreferences: {
      receiveAlertEmails: false,
      receiveAlertEmailTypes: [] as Array<boolean>,
      allAlertTypes: [] as Array<AlertTypes> | null,
    },
    loadingAlertTypes: false,
  }),

  computed: {
    userTypeItems() {
      return Object.values(OrganizationCustomerUserType).map((userType) => ({
        name: userType[0].toUpperCase() + userType.substring(1),
        value: userType,
      }));
    },
    isEditingExistingUser() {
      return this.existingOrgUserDetails != null;
    },
    showEmailPreferences() {
      return (
        this.isEmailPreferences &&
        this.emailPreferences.receiveAlertEmails &&
        this.emailPreferences.allAlertTypes &&
        this.emailPreferences.allAlertTypes.length > 0
      );
    },
    allFormFieldsValid() {
      if (!this.orgUserId) {
        return (
          this.allFieldsValid == true &&
          this.name != null &&
          this.email != null &&
          this.password != null &&
          this.password.length >= 6 &&
          this.orgUserType != null
        );
      }
      return true;
    },
    oldPasswordErrors() {
      if (this.password) {
        return this.oldPasswordRulesData
          .map((rule) => rule(this.password))
          .filter((errorMessage) => errorMessage !== true);
      }
      return [];
    },
    passwordErrors() {
      if (this.newPassword) {
        return this.passwordRulesData
          .map((rule) => rule(this.newPassword))
          .filter((errorMessage) => errorMessage !== true);
      }
      return [];
    },
  },

  mounted() {
    this.initExistingOrgUser(this.existingOrgUserDetails);
    this.isEmailPreferences = this.isEmail;
    if (this.isEmail && this.existingOrgUserDetails) {
      this.getAllAlertTypes(this.existingOrgUserDetails.user_type);
    }
    this.orgUserType =
      this.orgId === this.superAdminOrgId
        ? "superadmin"
        : this.orgId === this.technicianOrgId
        ? "technician"
        : this.orgUserType;
  },

  methods: {
    passwordRules(is_change_password: boolean) {
      return is_change_password
        ? [
            (v: string | null) =>
              this.existingOrgUserDetails != null ||
              (v && v.length >= 6) ||
              "Password must have at least 6 characters.",
          ]
        : this.passwordRulesData;
    },
    initExistingOrgUser(existingOrgUserDetails: OrganizationUser) {
      this.isEmailPreferences = this.isEmail;
      if (this.isEditingExistingUser) {
        this.name = existingOrgUserDetails.name;
        if (existingOrgUserDetails.address) {
          this.address = existingOrgUserDetails.address;
        }
        this.email = existingOrgUserDetails.email;
        this.orgUserType = existingOrgUserDetails.user_type;
        if (existingOrgUserDetails.receive_emails) {
          this.receiveAlertEmails = existingOrgUserDetails.receive_emails;
        }
        if (existingOrgUserDetails.receive_emails) {
          this.emailPreferences.receiveAlertEmails =
            existingOrgUserDetails.receive_emails;
        }
      }
    },

    resetForm() {
      this.name = null;
      this.address = null;
      this.email = null;
      this.orgUserType = null;
      this.password = null;
      this.newPassword = null;
      this.isEmailPreferences = false;
      this.emailPreferences.receiveAlertEmails = false;
      this.emailPreferences.receiveAlertEmailTypes = [];
      this.emailPreferences.allAlertTypes = [];
      (this.$refs.orgUserFormElm as VForm).resetValidation();
    },

    closeForm() {
      this.resetForm();
      this.$emit("close-form");
    },

    submitForm() {
      let orgUser = {
        name: this.name,
        email: this.email,
        address: this.address,
        user_type: this.orgUserType,
        receive_emails: this.emailPreferences.receiveAlertEmails,
      } as OrganizationUserUpdate;
      console.log("orgUser", orgUser);

      if (this.password) {
        orgUser = { ...orgUser, password: this.password };
      }

      if (this.isEmailPreferences && this.emailPreferences.allAlertTypes) {
        let receive_alert_types = [] as Array<number>;
        for (let i = 0; i < this.emailPreferences.allAlertTypes.length; i++) {
          if (this.emailPreferences.receiveAlertEmailTypes[i]) {
            receive_alert_types.push(
              this.emailPreferences.allAlertTypes[i]["id"]
            );
          }
        }
        orgUser["receive_alert_types"] = receive_alert_types;
        if (receive_alert_types.length == 0) {
          if (this.emailPreferences.receiveAlertEmails) {
            this.$dialog.message.error(
              "Atleast one Email alert should be selected.",
              {
                position: "top-right",
                timeout: 3000,
              }
            );
            return;
          }
          orgUser["receive_emails"] = false;
          orgUser["receive_alert_types"] = [];
        }
        this.updateUserEmailPreferences(orgUser);
        return;
      }

      if (this.isChangePassword) {
        if (this.password && this.newPassword) {
          orgUser["old_password"] = this.password;
          orgUser["password"] = this.newPassword;
        }
        this.changeUserPassword(orgUser);
      } else if (this.isEditingExistingUser) {
        this.updateUser(orgUser);
      } else {
        let newOrgUser: OrganizationUserCreate = {
          ...orgUser,
          organization_id: this.orgId,
        };
        this.createUser(newOrgUser);
      }
    },

    async createUser(orgUser: OrganizationUserCreate) {
      let newUser = await api.createOrgUser(orgUser);
      if (typeof newUser === "string") {
        this.$dialog.message.error(newUser, {
          position: "top-right",
          timeout: 3000,
        });
        return;
      }
      if (newUser) {
        console.log("New user created", newUser);
        this.closeForm();
        this.$emit("refresh-data");
      }
    },

    async updateUser(orgUser: OrganizationUserUpdate) {
      let updatedUser = await api.updateOrgUser(this.orgUserId, orgUser);
      if (updatedUser) {
        console.log("Existing user updated", updatedUser);
        this.closeForm();
        this.$emit("refresh-data");
      }
    },

    async updateUserEmailPreferences(orgUser: OrganizationUserUpdate) {
      let updatedUser = await api.updateOrgUserEmailPreferences(
        this.orgUserId,
        orgUser
      );
      if (updatedUser) {
        console.log(
          "Existing user aler email preferences updated",
          updatedUser
        );
        this.closeForm();
        this.$emit("refresh-data");
      }
    },

    async changeUserPassword(orgUser: OrganizationUserUpdate) {
      let updatedUser = await api.changeOrgUserPassword(orgUser);
      if (updatedUser) {
        console.log("User password updated", updatedUser);
        this.$emit("refresh-data");
        this.$dialog.message.info("Password changed Successfully.", {
          position: "top-right",
          timeout: 3000,
        });
        this.closeForm();
      } else {
        this.$dialog.message.error("Verification Error, please try again", {
          position: "top-right",
          timeout: 3000,
        });
      }
    },

    async getAllAlertTypes(user_type: OrganizationUserType) {
      this.loadingAlertTypes = true;
      this.emailPreferences.allAlertTypes = await api.getAllAlertTypes(
        user_type
      );
      if (
        this.emailPreferences.allAlertTypes &&
        this.emailPreferences.allAlertTypes.length > 0
      ) {
        if (this.existingOrgUserDetails.receive_alert_types) {
          this.emailPreferences.receiveAlertEmailTypes = Array(
            this.emailPreferences.allAlertTypes.length
          ).fill(false);
          for (let alertTypeId of this.existingOrgUserDetails
            .receive_alert_types) {
            const idx = this.emailPreferences.allAlertTypes.findIndex(
              (item) => {
                return item["id"] === alertTypeId;
              }
            );
            this.emailPreferences.receiveAlertEmailTypes[idx] = true;
          }
        } else {
          this.emailPreferences.receiveAlertEmailTypes = Array(
            this.emailPreferences.allAlertTypes.length
          ).fill(false);
        }
      }
      this.loadingAlertTypes = false;
    },

    showUserEmailPreferences() {
      if (this.existingOrgUserDetails) {
        this.isEmailPreferences = true;
        this.emailPreferences.receiveAlertEmails =
          this.existingOrgUserDetails.receive_emails;
        this.emailPreferences.receiveAlertEmailTypes =
          this.existingOrgUserDetails.receive_alert_types;
        this.getAllAlertTypes(this.existingOrgUserDetails.user_type);
      }
    },
  },

  watch: {
    existingOrgUserDetails(newOrgUserDetails) {
      this.initExistingOrgUser(newOrgUserDetails);
    },
    needsInit(show) {
      if (show) this.initExistingOrgUser(this.existingOrgUserDetails);
      else this.closeForm();
    },
  },
});
